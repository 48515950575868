import request from "../utils/request";

export const setCode = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "code/set",
    method: "get",
    params: query,
  });
};

export const inspectCode = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "index/inspect",
    method: "get",
    params: query,
  });
};


export const setName = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "user/set/name",
    method: "post",
    data: query,
  });
};


export const setuser = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "user/init/up",
    method: "get",
    params: query,
  });
};

//数据埋点
export const visitAdd = (query) => {
  return request({
    url: process.env.VUE_APP_URL + "statistics/visitAdd",
    method: "get",
    params: query,
  });
};



